.loverset-territory-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  background: #fff;
}

.loverset-product-image-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.loverset-image {
  width: 90vw;
  max-width: 420px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.territory-text {
  font-size: 22px;
  font-family: "Inter";
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.flags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  align-items: center;
  max-width: 90vw;
  margin: 0 auto;
  margin-bottom: 80px;
}

.flag-img {
  border-radius: 5px;
  width: 50px;
  height: auto;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.flag-img:hover {
  transform: scale(1.1);
}

@media (max-width: 600px) {
  .territory-text {
    font-size: 17px;
  }

  .flags-container {
    margin-bottom: 50px;
  }

  .flag-img {
    width: 30px;
  }
}