.footer {
    padding: 0px 0px; /* Add spacing around the footer */
    text-align: center;
  }
  
  .footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px; /* Space below the logo */
  }
  
  .footer-fish {
    width: 170px; /* Adjust size as needed */
    height: auto;
  }
  
  .footer-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
  }
  
  .footer-support,
  .footer-copyright,
  .footer-privacy {
    font-family: 'Inter';
    font-size: 15px;
    font-weight: 400;
    color: #666666; /* Link color */
    flex: 1; /* Equal width for each section */
    text-align: center;
    margin: 0px 0; /* Add spacing for mobile stacking */
  }
  
  .footer-link {
    text-decoration: none;
    color: #666666; /* Link color */
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .footer-details {
      flex-direction: column; /* Stack items vertically */
    }
  
    .footer-support {
      order: 1; /* First */
      margin-bottom: 10px;
    }

    .footer-fish {
      width: 145px;     
    }
  
    .footer-privacy {
      order: 2; /* Second */
    }
  
    .footer-copyright {
      order: 3; /* Third */
    }
  }