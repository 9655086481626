.welcome-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(128, 128, 128, 0.8); /* semi-transparent grey */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 9999;
}

.welcome-modal-container {
  background: #fff;
  width: 100%; 
  /* border-radius: 8px 8px 0 0; */
  padding: 20px;
  position: relative;
  transform: translateY(100%);
  transition: transform 0.8s ease-in-out;
}

/* Animate into view */
.welcome-modal-container.visible {
  transform: translateY(0);
}

.welcome-modal-close {
  background: none;
  border: none;
  font-size: 1.2em;
  line-height: 1;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.welcome-modal-fish {
  display: block;
  margin: 0 auto 20px;
  margin-top: -110px;
  max-height: 150px;
}

.welcome-modal-title {
  font-family: "Lulo Clean One Bold";
  text-align: center;
  margin: 10px 0;
  font-size: 2em;
  color: #FF4040;
}

.welcome-modal-subtitle {
  font-size: 18px;
  font-family: "Inter";
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  margin-bottom: 30px;
}

/* Wrapper for the select, input, and button */
.welcome-modal-input-wrapper {
  display: flex;
  flex-direction: column; /* stack vertically */
  align-items: center;    /* center horizontally */
  max-width: 500px;           /* set a fixed width or any width you like, e.g. 20% of viewport: width: 20vw; */
  margin: 0 auto;         /* center the wrapper in the container */
}

/* Adjust select and input styles */
/* .welcome-modal-select, */
.welcome-modal-input {
  box-sizing: border-box;
  width: 100%;            /* now they take 100% of the wrapper width */
  padding: 10px;
  border: 2px solid #FF4040;
  border-radius: 4px;
  font-size: 1em;
  margin-bottom: 15px;
  display: block;         /* ensure block-level to stack nicely */
}

.welcome-modal-select {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border: 2px solid #FF4040;
  border-radius: 4px;
  font-size: 1em;
  margin-bottom: 15px;
  display: block;
  background: url("data:image/svg+xml,%3Csvg height='10' width='10' viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23333' d='M0 0l5 5 5-5z'/%3E%3C/svg%3E") no-repeat right 10px center/10px auto;
  -webkit-appearance: none; /* hides default arrow in Safari */
  -moz-appearance: none;    /* hides default arrow in Firefox */
  appearance: none;         /* modern browsers */
  background-color: #fff;   /* ensure background is white for contrast */
}

/* Submit button */
.welcome-modal-submit {
  display: block;
  max-width: 150px;      /* set a fixed max width */
  width: 100%;           /* ensure it shrinks properly within that max width */
  background: #FF4040;
  color: #fff;
  border: none;
  font-size: 1em;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  margin: 10px auto 60px; /* center horizontally by using auto on left/right */
}

@media (max-width: 600px) {
  .welcome-modal-title {
    font-size: 1.5em;
  }

  .welcome-modal-subtitle {
    font-size: 16px;
  }
  

  .welcome-modal-fish {
    max-height: 120px;
    margin-top: -92px;
  }

}