.chatbox-container {
  position: relative; /* important */
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 50px;
}

.instructions-icon-link {
  position: absolute;
  top: 50%;
  right: -100px; /* Adjust as needed: now the icon will be to the right of the chatbox */
  transform: translateY(-50%);
  text-decoration: none;
  color: inherit;

  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
  z-index: 3; /* ensure above the chatbox */
}

.instructions-icon-link:hover {
  transform: translateY(-50%) scale(1.05);
}

.instructions-icon-box {
  background: rgba(255,255,255,0.9);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 0 15px rgba(255,255,255,0.5);
  animation: glowPulse 2s infinite alternate;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
}

.instructions-icon-image {
  width: 50px;
  height: auto;
  border-radius: 4px;
}

.instructions-icon-text {
  margin-top: 5px;
  margin-bottom: 0px;
  text-align: center;
  font-size: clamp(12px, 3vw, 16px);
  font-weight: 600;
  color: #333;
}

.bot-image-img {
  width: 85px;
  height: auto;
  position: absolute;
  right: 83%;
  top: -18px;
  transform: translateY(-50%);
  z-index: 3; /* ensure above the chatbox */
}

@media (max-width: 768px) {
  .chatbox-container {
    margin-top: 140px;
    /* margin-bottom: 160px; */
  }

  .bot-image-img {
    width: 50px;
    height: auto;
    top: -48px;
    left: 20px;    
  }

  .instructions-icon-link {
    top: -60px;
    right: 30px;
  }

  .instructions-icon-box {
    padding: 0px;
    padding-top: 1px;    
  }  
}

@keyframes glowPulse {
  from {
    box-shadow: 0 0 5px rgba(255,255,255,0.5), 0 0 10px rgba(255,0,0,0.3);
  }
  to {
    box-shadow: 0 0 10px rgba(255,255,255,0.9), 0 0 20px rgba(255,0,0,0.6);
  }
}