.bamboo-kit-1-resellers {
    max-width: 1250px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .product-title {
    text-align: center;
    color: #ff4040;
    font-family: "Lulo Clean One Bold", sans-serif; /* Custom font if needed */
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 100px;
  }
  
  /* Image Grid */
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 40px;
    margin-bottom: 60px;
  }
  
  .image-card {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    /* transition: transform 1.3s ease; */
    border: 1px solid #e0e0e0;
  }
  
  .image-card img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 1.3s ease;
  }
  
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* semi-transparent dark overlay */
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .image-card:hover .image-overlay {
    opacity: 1;
  }

  .image-card:hover img {
    transform: scale(1.1);
  }
  
  /* Lightbox Overlay */
  .lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .lightbox-content {
    position: relative;
    max-width: 90vw;
    max-height: 80vh;
  }
  
  .fullscreen-img {
    width: 50vw;
    height: auto;
    display: block;
  }
  
  /* Close and Arrow Buttons */
  .close-button,
  .arrow-button {
    position: absolute;
    background: transparent;
    color: #fff;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    z-index: 10000;
  }
  
  .close-button {
    top: 20px;
    right: 20px;
  }
  
  .left-arrow {
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
  }
  
  .right-arrow {
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
  }
  
  /* Bottom Section: Two Cards */
  .bottom-section {
    display: flex;
    gap: 45px;
    margin-bottom: 40px;
  }
  
  .feature-card {
    flex: 1;
    background-color: #f4f4f4;
    padding: 20px;
    text-align: center;      /* Center all text within the card */
    margin-bottom: 20px;
  }

  .shipping-info {
    display: inline-block; /* treat this block as an inline element so it can be centered */
    text-align: left;      /* text inside is left-aligned */
    margin-top: 0px;
  }

  .shipping-info p {
    /* margin-top: 0; */
    line-height: 1.15;
    margin-bottom: 60px; /* or 0 if you want lines squashed together */
  }
  
  .feature-card h2 {
    text-align: center;
    font-family: "Avenir", sans-serif;
    letter-spacing: 0.1em;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 70px;
  }
  
  .feature-card ul {
    /* margin: 0 auto; */
    display: inline-block;   /* Allow margin: 0 auto to center it */
    text-align: left;       
                            
    padding-left: 80px;      /* Optionally add a bit of padding for bullet visibility */
    padding-right: 50px;
    line-height: 1.4;
    margin-bottom: 60px;
  }

  .feature-card li {
    margin-bottom: 20px;
  }  
  /* PDF Download */
  .pdf-download {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .pdf-link {
    display: flex;               /* was inline-flex; now use full flex for easy column layout */
    flex-direction: column;      /* stack icon above text */
    align-items: center;         /* center horizontally */
    justify-content: center;     /* optionally center vertically if needed */
    text-decoration: none;
    color: #333;
    gap: 8px;                    /* space between icon and text */
  }
  
  .pdf-icon {
    width: 80px;
    height: 80px;
  }
  
  /* Responsive Breakpoints */
  @media (max-width: 768px) {
    .product-title {
      font-size: 24px;
    }    
    .bottom-section {
      flex-direction: column;
    }
  }
  
  @media (max-width: 400px) {
    .product-title {
        font-size: 20px;
        margin-bottom: 50px;
      }
    .image-grid {
      grid-template-columns: 1fr;
    }
    .image-card {
        margin-left: 30px;
        margin-right: 30px;
    }
    .feature-card ul {
      padding-left: 25px;      /* Optionally add a bit of padding for bullet visibility */
      padding-right: 10px;      
    }
    .bottom-section {
      gap: 5px;
    }
  }