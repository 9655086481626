/* Shared video styles */
.video {
  width: 100%;
  border: 1px solid #ddd; /* Keeps a subtle border */
  border-radius: 5px; /* Use 5px for a more modern, subtle rounding */
  overflow: hidden; /* Ensures content like videos doesn't spill out */
  object-fit: cover; /* Ensures the thumbnail fits properly */
}
  
  /* Mobile Carousel */
  .carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }
  
  .carousel-slide {
    min-width: 100%;
    transition: opacity 0.3s ease;
  }
  
  .carousel-slide.inactive {
    opacity: 0;
  }
  
  /* Pagination dots */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    background-color: #ddd;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #007bff;
  }
  
  /* Button styles */
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    padding: 10px;
  }
  
  .carousel-button.left {
    left: 10px;
  }
  
  .carousel-button.right {
    right: 10px;
  }
  
/* Desktop Grid Layout */
@media (min-width: 768px) {
    .carousel-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Enforce 2 videos per row */
      gap: 20px;
      overflow: visible;
    }
  
    .carousel,
    .carousel-slide,
    .pagination,
    .carousel-button {
      display: none; /* Hide carousel-specific elements */
    }
  }

  /* General Video Styling */
.video-wrapper {
  position: relative;
}

.video-title {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #333;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: left;
  padding-left: 0px;
}

/* Mobile Overlay Styling */
/* .video-overlay-title {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background: rgba(0, 0, 0, 0.7);
  padding: 5px 8px;
  border-radius: 5px;
  text-align: center;
} */

/* Mobile-Only Styling */
@media (max-width: 768px) {
  .video-title {
    display: none; /* Hide desktop titles */
  }
}