.privacy-policy-page {
  background-color: #f2f2f2; /* Light grey background for the page */
  padding: 10px 0; /* Add spacing around the privacy policy container */
  /* min-height: calc(100vh - 200px); */
}

.privacy-policy-container {
    text-align: center; 
    max-width: 900px; /* Limit the width of the container */
    margin: 50px auto; /* Center the container on the page */
    padding: 20px;
    background-color: #fff; /* White background */
    border: 1px solid #494848; /* Subtle border */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  }
  
  .privacy-policy-title {
    font-family: "Avenir", sans-serif;
    color: #665E5E;
    font-size: 23px;
    font-weight: bold;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 0px;
    position: relative;
    display: inline-block;
  }
  
  .privacy-policy-title::after {
    content: ""; /* Creates the underline */
    display: block;
    width: 200%; /* Length of the underline relative to the text */
    height: 1px; /* Thickness of the underline */
    background-color: #665E5E; /* Match the text color */
    margin: 10px auto 0; /* Space above the underline and center it */
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }

  .privacy-policy-content {
    display: flex;
    justify-content: space-between;
    margin: 40px;
    gap: 100px; /* Space between the columns */
  }
  
  .privacy-policy-left,
  .privacy-policy-right {
    flex: 1; /* Equal width for both columns */
    font-family: "Inter", sans-serif;
    color: #4d4b4b;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.3; /* Improve readability */
    text-align: justify;
  }
  
  .privacy-policy-left h3 {
    font-family: "Inter", sans-serif;
    color: #4a4949;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 40px;
  }

  .privacy-policy-right h3 {
    font-family: "Inter", sans-serif;
    color: #4a4949;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 36px;
  }
  
  .privacy-policy-left p,
  .privacy-policy-right p {
    margin-bottom: 15px;
  }
  
  .privacy-policy-underline {
    width: 80px; /* Length of the underline */
    height: 3px;
    background-color: #ff0000; /* Red underline color */
    margin: 20px auto 0; /* Center and space it properly */
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    .privacy-policy-content {
      flex-direction: column; /* Stack the columns vertically */
      gap: 0; /* Remove gap when stacked */
    }
  
    .privacy-policy-left,
    .privacy-policy-right {
      margin-bottom: 20px; /* Add spacing between sections */
    }
  }