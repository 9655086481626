.chopsticks-page {
  background: #fff;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.chopsticks-content {
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  width: 100%;
}

.chopsticks-image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}

.chopsticks-image {
  max-width: 100%;
  height: auto;
  display: block;
}

.chopsticks-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 20px;
}

.chopsticks-title {
  font-family: "Lulo Clean One Bold";
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 26px;
  color: #FF4040;
}

.chopsticks-paragraph {
  font-family: "Inter";
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  margin-bottom: 10px;
  text-align: justify;
}

.highlighted-sentence {
  color: #3B7A57; 
  font-weight: bold;
}

@media (max-width: 768px) {
  .chopsticks-content {
    flex-direction: column;
    align-items: center;
  }

  .chopsticks-image-section,
  .chopsticks-text-section {
    padding: 0;
    width: 100%;
  }

  .chopsticks-image {
    max-width: 90vw;
    margin-bottom: 20px;
  }

  .chopsticks-title {
    font-size: 1.5rem;
    text-align: center;
  }

  .chopsticks-paragraph {
    font-size: 0.95rem;
    margin-bottom: 15px;
  }
}