@font-face {
    font-family: "Dolce Vita";
    src: url("./fonts/dolce_vita.ttf") format("truetype");
    font-weight: normal;
  }
  
  @font-face {
    font-family: "Dolce Vita Light";
    src: url("./fonts/dolce_vita_light.ttf") format("truetype");
    font-weight: normal;
  }
  
  @font-face {
    font-family: "Dolce Vita Heavy Bold";
    src: url("./fonts/dolce_vita_heavy_bold.ttf") format("truetype");
    font-weight: normal;
  }

@font-face {
    font-family: "Lulo Clean One Bold";
    src: url("./fonts/lulo_clean_one_bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/Inter-VariableFont_opsz,wght.ttf") format("truetype");
  font-weight: 100 900; /* Range of weights available in the font */
  font-style: normal; /* For non-italic styles */
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/Inter-Italic-VariableFont_opsz,wght.ttf") format("truetype");
  font-weight: 100 900; /* Same weight range for italic */
  font-style: italic; /* For italic styles */
}

@font-face {
  font-family: "Avenir";
  src: url("./fonts/avenir_lt_std_85_heavy.otf") format("truetype");
  font-weight: bold; /* Same weight range for italic */
  font-style: normal; /* For italic styles */
}


@font-face {
  font-family: "San Franciso";
  src: url("./fonts/sf-pro-text/SFProText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "San Franciso";
  src: url("./fonts/sf-pro-text/SFProText-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}