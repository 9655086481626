.product-section {
    margin-bottom: 20px; /* Center the section with some vertical spacing */
    padding: 20px; /* Add some inner spacing */
    max-width: 100%; /* Limit the width for readability */
    text-align: center;
  }
  
  .product-section-title {
    text-align: center;
    font-family: "Avenir", sans-serif;
    color: #665E5E;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
  }

  .product-section-title::after {
    content: ""; /* Creates the underline */
    display: block;
    width: 50%; /* Length of the underline relative to the text */
    height: 1px; /* Thickness of the underline */
    background-color: #665E5E; /* Match the text color */
    margin: 10px auto 0; /* Space above the underline and center it */
  }
  
  .product-line {
    margin-bottom: 10px; /* Add spacing between product lines */
  }