/* General styles */
.support-page {
  display: flex;
  flex-direction: row;
  min-height: 80vh; /* Full viewport height */
  height: auto; /* Prevent resizing */
  padding: 20px;
  background: #fff;
  overflow-y: auto; /* Enable scrolling if content overflows */
}

.support-half {
  width: 50%; /* Keep the halves equally sized */
  display: flex;
  justify-content: center;
  align-items: center;
}

.support-card-title {
  text-align: center;
  color: #575454;
  font-size: 17px;
  font-family: "Inter";
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 40px;
}

.left {
  background: #d7d5d5;
}

.right {
  background: #f0eeee;
}

.support-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  text-align: center; /* Center form elements */
}

.support-form input,
.support-form select,
.support-form textarea {
  width: 90%; /* Prevent overflow */
  margin: 0 auto; /* Center inputs */
  padding: 8px;
  border: 1px solid #ccc;
}

.support-form textarea {
  resize: none;
}

/* Submit button styles */
.submit-button {
  width: 40%;
  padding: 10px;
  background: #030303;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  margin: 20px auto; /* Center the button horizontally and add spacing from form inputs */
  display: block; /* Ensures the button behaves as a block-level element */
}


.submit-button:hover {
  background: #e03030;
}

/* Left card (form) styles */
.support-card-left {
  width: 400px; /* Fixed width */
  height: 550px; /* Fixed height */
  background: white;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(36, 35, 35);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden; /* Prevent overflow within the card */
  margin-top: 100px;
  margin-bottom: 100px;
}


/* Right card (contact info) styles */
.support-card-right {
  width: 400px; /* Fixed width */
  height: 550px; /* Fixed height */
  background: white;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(36, 35, 35);
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.contact-us-text {
  color: #575454;
  font-family: "Inter";
  font-weight: 600;
}

.contact-logo {
  display: block;
  margin: 10px auto 20px;
  max-width: 200px;
}

.contact-divider {
  margin: 20px auto;
  height: 2px;
  width: 10%;
  background-color: #ff0000; /* Red underline color */
  border: 1px solid #ff4040;
  margin-top: 50px;
  margin-bottom: 50px;
}

select {
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  border: 1px solid grey;
  padding: 5px;
  color: black;
}

/* Mobile responsive design */
@media (max-width: 768px) {
  .support-page {
    flex-direction: column; /* Stack cards vertically */
    padding: 20px 0; /* Reduce padding for smaller screens */
  }

  .support-half {
    width: 100%; /* Use the full width for each half */
    justify-content: center; /* Center cards on smaller screens */    
    min-width: 400px;
  }

  .support-card-left,
  .support-card-right {
    width: 90%; /* Keep cards within the screen width */
    max-width: 400px; /* Ensure cards do not grow larger than intended */
    min-width: 350px;
    height: auto;
    min-height: 550px; /* Fixed height */
    margin: 10px auto; /* Space between stacked cards */
  }
}