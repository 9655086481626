/* HomePage.css */

.homepage-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 40vh;
    background: #fff;
    padding: 20px;
  }
  
  .welcome-section {
    /* Keep this simple so it stays near top */
    text-align: center;
    margin-bottom: 30px;
  }
  
  .homepage-subtitle {
    font-family: "Lulo Clean One Bold", sans-serif;
    color: #FF4040; 
    font-weight: bold;
    letter-spacing: 0.1em; 
    font-size: clamp(10px, 4vw, 28px); 
    margin: 0;
    margin-top: 0px;
  }
  
  .icons-section {
    flex: 1; 
    /* This allows the icons section to take remaining vertical space if needed */
    display: flex;
    justify-content: center;
    align-items: center;
    /* The icons section is now controlled independently */
  }
  
  .homepage-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: clamp(10px, 8vw, 60px);
    width: 100%;
    max-width: 1000px;
    margin-top: 30px;
    /* The icons will shrink as per previous logic */
  }
  
  .option-link {
    text-decoration: none;
    color: inherit;
  }
  
  .option-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    width: 100%;
    flex: 1 1 400px;
    flex-shrink: 1;
    transition: transform 0.3s ease;
  }
  
  .option-box:hover {
    transform: scale(1.05);
  }
  
  .option-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
  
  .option-text {
    margin-top: clamp(10px, 4vw, 28px);;
    font-size: clamp(10px, 4vw, 28px);
    font-weight: 600;
    color: #333;
  }