.product-card {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%; /* Square aspect ratio */
    overflow: hidden;
    /* border-radius: 10px; */
    text-decoration: none; /* Remove underline on links */
  }
  
  .product-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 1.3s ease;
  }
  
  .product-card:hover .product-image {
    transform: scale(1.1); /* Grow slightly on hover */
  }
  
  .product-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Grey overlay */
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: bold;
  }
  
  .product-card:hover .product-overlay {
    opacity: 1;
  }
  
  .product-name {
    font-family: "Inter", sans-serif; /* Custom font if needed */
    font-weight: 500;
    letter-spacing: 0.1em; /* Match original spacing */
    font-size: 28px; /* Adjust size as needed */
    text-align: center;
    padding: 10px;
  }