.resellers-page {
  margin-bottom: 20px; /* Center the section with some vertical spacing */
  padding: 20px; /* Add some inner spacing */
  max-width: 100%; /* Limit the width for readability */
  text-align: center;
}

.resellers-page-title {
  text-align: center;
  color: #ff4040;
  font-family: "Lulo Clean One Bold", sans-serif; /* Custom font if needed */
  font-weight: bold;
  letter-spacing: 0em; /* Match original spacing */
  font-size: 32px; /* Adjust size as needed */
  margin-bottom: 50px;
}

.resellers-page-subtitle {
  text-align: center;
  font-family: "Avenir", sans-serif;
  color: #665E5E;
  letter-spacing: 0.1em; /* Match original spacing */
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0px;
  position: relative;
  display: inline-block;
}

.resellers-page-subtitle::after {
  content: ""; /* Creates the underline */
  display: block;
  width: 50%; /* Length of the underline relative to the text */
  height: 1px; /* Thickness of the underline */
  background-color: #665E5E; /* Match the text color */
  margin: 10px auto 0; /* Space above the underline and center it */
}