.video-tutorials-container {
    max-width: 95%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .video-tutorials-title {
    font-family: "Lulo Clean One Bold", sans-serif;
    color: #FF4040;
    text-align: center;
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 150px;
  }
  
  .video-section {
    margin-bottom: 80px;
  }
  
  .video-section-title {
    font-family: "Inter", sans-serif;
    font-weight: 550;
    text-align: center;
    letter-spacing: 2px;
    color: #538018;;
    font-size: 24px;
    margin-bottom: 60px;
  }

  @media (max-width: 768px) {
    .video-tutorials-title {
      font-size: 24px;
    }
    .video-section-title {
      font-size: 22px;
    }
  }

  @media (max-width: 400px) {
    .video-tutorials-title {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .video-section-title {
      font-size: 19px;
    }
  }