.product-line {
    margin: 40px 20px;
  }
  
  .product-line-title {
    text-align: center;
    font-family: "Lulo Clean One Bold", sans-serif; /* Custom font if needed */
    font-weight: bold;
    letter-spacing: 0.1em; /* Match original spacing */
    font-size: 20px; /* Adjust size as needed */
    margin-bottom: 0px;
  }

  /* Specific colors for each title */
.product-line-title.aya-classic {
  color: #ff4040; /* Red for AYA CLASSIC */
}

.product-line-title.aya-eco {
  color: #3974A8; /* Blue for AYA ECO */
}
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Force three items per row for desktop */
    gap: 40px;
    justify-content: start; /* Align rows left */
  }
  
  @media (max-width: 1200px) {
    .product-grid {
      grid-template-columns: repeat(2, 1fr); /* Dynamic layout for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .product-grid {
      grid-template-columns: 1fr; /* One product per row for mobile */
    }
  }