@import "./fonts.css";

.chatbox {
  max-width: 550px;
  margin: 20px auto;
  background-color: #f9f9f9;
}

.chatbox-messages {
  display: flex;
  flex-direction: column; /* Stack messages vertically */
  height: 400px;
  overflow-y: auto;
  border: 2px solid gray;
  padding: 10px;
  border-radius: 10px;
  background-color: #f8f5f5;
}

.message {
  margin-bottom: 25px;
}

.message strong {
  font-family: "Inter";
  font-weight: 700;
}

.message.user {
  font-family: "Inter";
  font-weight: 450;
  font-size: 18px;
  background-color: #e6e6e6; /* Light grey bubble */
  margin-left: auto; /* Align to the right */
  padding: 8px 12px;
  border-radius: 12px; /* Rounded corners */
  max-width: 70%; /* Limit the bubble width */
  text-align: left; /* Align text inside the bubble */
  color: #09801b; /* Green text for user messages */
  display: inline-block; /* Allow dynamic bubble sizing */
}

.message.assistant {
  font-family: "Inter";
  font-weight: 450;
  font-size: 18px;
  max-width: 70%;
  text-align: left;
  color: #333; 
}

.typing-message {
  margin-bottom: 10px;
  display: inline; /* Render "Bot:" and message as inline content */
  text-align: left;
}

.typing-message strong {
  margin-right: 0px;
}

.chatbox-input {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;  
}

.chatbox-input input {
  font-family: "Inter";
  font-weight: 450;
  font-size: 18px;
  flex: 1;
  padding: 8px;
  border: 2px solid gray;
  border-radius: 5px;
}

.chatbox-input button {
  padding: 8px 12px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  opacity: 1;
}

.chatbox-input button:hover {
  background-color: #0056b3;
}

.chatbox-input button:disabled {
  background-color: #007BFF;
  opacity: 0.6;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .chatbox-messages {
    height: 300px;
  }

  .chatbox-container {
    padding-top: 0px;
  }

  .chatbox-input input {
    font-size: 16px;
  }

  .chatbox-input input:focus {
    outline: none;
    box-shadow: none;
  }

  .message.assistant {
    font-size: 15px;
  }

  .message.user {
    font-size: 15px;
  }

}