.product-page-title {
  font-size: 36px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0px;
  color: #ff4040;
  font-family: "Lulo Clean One Bold", sans-serif; /* Custom font if needed */
  font-weight: bold;
}

.buy-link-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.buy-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  background-color: #ffffff; /* Amazon-ish color or your choice */
  color: #333;
  padding: 10px 15px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 32px;
  transition: transform 0.6s ease;
}

.buy-link:hover {
  transform: scale(1.05);
}

.amazon-logo {
  width: 270px; 
  height: auto;
  /* margin-top: 10px; */
  /* margin-left: 10px; */
}

h1.video-tutorials-title, h1.instructions-title {
  margin-top: 50px;
}

/* Positioning the chatbot icon fixed at top-right of the viewport */
.chatbot-icon-link {
  position: fixed;
  top: 150px;
  right: 50px;
  z-index: 9999; /* Ensure it's above everything else */
  text-decoration: none;
  color: inherit;

  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
}

.chatbot-icon-link:hover {
  transform: scale(1.05);
}

.chatbot-icon-box {
  background: rgba(255,255,255,0.9);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 0 15px rgba(255,255,255,0.5);
  animation: glowPulse 2s infinite alternate;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chatbot-icon-image {
  width: 50px;
  height: auto;
  border-radius: 4px;
}

.chatbot-icon-text {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: clamp(10px, 3vw, 16px);
  font-weight: 600;
  color: #333;
}

@media (max-width: 768px) {
  .product-page-title {
    font-size: 32px;
    margin-bottom: 80px;
  }
  .chatbot-icon-link {
    top: 80px;
    right: 45px;
  }
  .chatbot-icon-box {
    padding: 3px;
  }
}

@media (max-width: 400px) {
  .product-page-title {
    font-size: 24px;
    margin-top: 85px;
    margin-bottom: 30px;
  }
  .buy-link {
    font-size: 22px;
  }
  .buy-link-container {
    margin-bottom: 0px;
  }
  .amazon-logo {
    width: 130px;
    margin-left: 5px;
  }
  .chatbot-icon-link {
    top: 110px;
    right: 15px;
  }
}

/* Glow animation for consistency */
@keyframes glowPulse {
  from {
    box-shadow: 0 0 5px rgba(255,255,255,0.5), 0 0 10px rgba(0,0,255,0.3);
  }
  to {
    box-shadow: 0 0 10px rgba(255,255,255,0.9), 0 0 20px rgba(0,0,255,0.6);
  }
}