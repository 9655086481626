.pdf-instructions {
    display: flex; /* Use flexbox for even spacing */
    flex-wrap: wrap; /* Allow items to wrap onto the next row */
    justify-content: center; /* Evenly distribute items with space between */
    align-items: center; /* Align items vertically */
    max-width: 650px; /* Constrain the section width for desktop */
    margin: 0 auto; /* Center the entire section horizontally */
    gap: 2  0px; /* Add consistent spacing between rows */
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .instructions-title {
    font-family: "Lulo Clean One Bold", sans-serif;
    color: #FF4040;
    text-align: center;
    font-size: 30px;
    letter-spacing: 5px;
    font-weight: bold;
    margin-top: 100px;
    margin-bottom: 50px;
  }
  
  .pdf-card {
    flex: 0 0 33%; /* Ensure each card takes 30% of the row width (3 per row max) */
    display: flex; /* Add flexbox to center content within the card */
    flex-direction: column; /* Stack elements (icon + title) vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease;
    height: 150px; /* Ensure consistent card height */
  }

  .pdf-card a {
    text-decoration: none;
  }
  
  .pdf-card:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
  
  .pdf-icon {
    width: 100px;
    height: 100px;
    display: flex; /* Use flexbox to center the icon */
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    margin-bottom: 0px;
    background-color: #fff; /* Background for the logo */
    background-image: url("../../public/aya_website_data/adobe_acrobat_logo.avif");
    background-size: contain; /* Adjust size as needed */
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .pdf-icon::after {
    content: ""; /* Remove "PDF" text */
  }
  
  .pdf-title {
    font-family: "Inter";
    font-size: 15px;
    font-weight: 400;
    color: #333;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .pdf-instructions {
      max-width: 100%; /* Allow the section to use full width on mobile */
      justify-content: center; /* Center align PDFs */
    }
    .instructions-title {
        font-family: "Lulo Clean One Bold", sans-serif;
        color: #FF4040;
        text-align: center;
        font-size: 24px;
        letter-spacing: 5px;
        font-weight: bold;
        margin-top: 100px;
        margin-bottom: 50px;
      }
  
    .pdf-card {
      flex: 0 0 90%; /* Each card takes 90% of the row width on mobile */
    }
  }