@import "./fonts.css";

/* Header Styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    position: static;
    top: 0;
    background: white;
    z-index: 1000;
    /* border-bottom: 1px solid #ccc; */
    margin-top: -15px;
}
  
/* Logo */
.logo {
    height: 190px;
    cursor: pointer;
    /* margin-left: 50px; */
}

.logo-link {
  display: inline-block;
  text-decoration: none; /* Remove any default styling */
}

  
/* Navigation Links */
.nav-links {
    display: flex;
    gap: 15px;
    margin-right: 25px;
}
  
/* Default nav links */
.nav-link {
    font-family: "Dolce Vita", sans-serif;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7); /* Start slightly greyed-out */
    text-shadow: 0 0 0.3px;
    text-decoration: none;
    letter-spacing: 1.5px; /* Slight letter spacing */
    transition: color 0.3s ease, opacity 0.3s ease; /* Smooth transitions */
}
  
/* Hover effect: Transition to more grey */
.nav-link:hover {
    color: rgba(0, 0, 0, 0.5);
    opacity: 0.7; /* Slight fade effect */
}
  
  /* Social Icons */
  .social-icons {
    display: flex;
    gap: 10px;
    margin-left: 25px;
    margin-right: 150px;
  }
  
  .social-icon {
    width: 35px;
    height: 35px;
  }
  
  /* Hamburger Menu */
  .hamburger-menu {
    color: rgb(97, 92, 92);
    display: none;
    background: none;
    border: none;
    font-size: 35px;
    cursor: pointer;
    -webkit-appearance: none; /* Remove Safari's default styles */
    appearance: none;
  }

  /* Full-page overlay */
.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent grey */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above all other elements */

    opacity: 0;
    animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
  
  /* Mobile Navigation */
.mobile-nav {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 80%; /* Adjust width for smaller screens */
    max-width: 300px; /* Limit the menu size */
    text-align: center;

    transform: translateY(-20px);
    animation: slideDown 0.3s forwards;
}

@keyframes slideDown {
    to {
        transform: translateY(0);
    }
}

.mobile-nav .nav-link {
    display: block;
    font-size: 18px;
    margin: 10px 0;
    text-decoration: none;
    color: black;
}

.mobile-nav hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin: 15px 0;
}
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .header {      
      margin-top: 15px;
      padding-left: 10px;
      padding-right: 0px;
    }
    .logo {
      height: 100px;
      margin-left: 20px;
    }
    .social-icons {
      margin-left: 0px; /* Reduce left spacing on tablets */
      margin-right: 0px;
      gap: 5px;
    }
    .social-icon {
      width: 25px;
      height: 25px;      
    }
    .nav-links {
      display: none;
      margin-right: 10px;
    }
    .nav-link {
      font-size: 14px;
      letter-spacing: 1.2px; /* Adjust spacing for smaller text */
    }
    .hamburger-menu {
      display: block;
      margin-left: 0px;
      margin-right: 10px;      
      padding-right: 0px;      
    }
  }