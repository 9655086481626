.resellers-product-grid {
    margin: 40px 20px;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Force three items per row for desktop */
    gap: 40px;
    justify-content: start; /* Align rows left */
  }
  
  @media (max-width: 1200px) {
    .product-grid {
      grid-template-columns: repeat(2, 1fr); /* Dynamic layout for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .product-grid {
      grid-template-columns: 1fr; /* One product per row for mobile */
    }
  }